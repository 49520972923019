import contactForm, { ContactFormStepContext } from '~/steps/contact-form';

// Add the imported StepDefinition from the steps in the order
// the steps should be shown in the assistant.
const steps = [contactForm];

// Add the imported context interface for the steps
export interface AssistantContext {
  contactForm: ContactFormStepContext;
}

export default steps;
