









import { defineComponent } from '@vue/composition-api';
import GcDivider from '@/components/primitives/GcDivider.vue';

export default defineComponent({
  components: {
    GcDivider,
  },
});
