import ContactForm from './ContactForm.vue';
import { StepId } from '~/consts/assistant-steps';
import {
  FormCheckboxValueType,
  FormMultipleChoiceValueType,
  FormSelectValueType,
  FormTextFieldValueType,
  StepDefinition,
} from '@/interfaces/step-definition';
import { StepTypes } from '@/consts/step-types';
import { AssistantContext } from '~/steps/steps';
import {
  buildFormCheckbox,
  buildFormMultipleChoiceField,
  buildFormSelect,
  buildFormTextArea,
  buildFormTextField,
} from '@/lib/forms/builder';
import { ValidationType } from '@/consts/validation-types';
import { sendMail } from '@/lib/submit/send-mail';
import { getMarkdownData } from '@/lib/formatter/context';

export interface ContactFormStepContext extends AssistantContext {
  subjects: FormMultipleChoiceValueType;
  salutation: FormSelectValueType;
  firstName: FormTextFieldValueType;
  lastName: FormTextFieldValueType;
  streetAndNumber: FormTextFieldValueType;
  zipcode: FormTextFieldValueType;
  city: FormTextFieldValueType;
  phone: FormTextFieldValueType;
  note: FormTextFieldValueType;
  privacyConfirmation: FormCheckboxValueType;
}

const config: StepDefinition = {
  component: ContactForm,
  fields: [
    buildFormTextField('customerNumber'),
    buildFormMultipleChoiceField('subjects', {
      component: {
        columns: 2,
        options: [
          {
            value: 'carPower',
          },
          {
            value: 'testDriveEvents',
          },
          {
            value: 'promotions',
          },
          {
            value: 'upcomingEvents',
          },
        ],
      },
    }),
    buildFormSelect('salutation', {
      component: {
        items: ['mr', 'mrs', 'divers'],
      },
    }),
    buildFormTextField('firstName', {
      required: true,
    }),
    buildFormTextField('lastName', {
      required: true,
    }),
    buildFormTextField('streetAndNumber', {
      required: true,
    }),
    buildFormTextField('zipcode', {
      required: true,
      validation: [ValidationType.postalCodeDE],
    }),
    buildFormTextField('city', {
      required: true,
    }),
    buildFormTextField('email', {
      component: {
        type: 'email',
      },
      required: true,
      validation: [ValidationType.email],
    }),
    buildFormTextField('phone', {
      validation: [ValidationType.phoneNumber],
    }),
    buildFormTextArea('note'),
    buildFormCheckbox('marketing', {
      component: {
        showLabel: true,
      },
      required: true,
      outputFormatter: ({ formattedLabel, rawValue, i18n }) => ({
        formattedLabel,
        formattedValue: (rawValue
          ? i18n.t('switch.yes')
          : i18n.t('switch.no')) as string,
      }),
    }),
    buildFormCheckbox('privacyConfirmation', {
      required: true,
      outputFormatter: ({ formattedLabel, rawValue, i18n }) => ({
        formattedLabel,
        formattedValue: (rawValue
          ? i18n.t('switch.yes')
          : i18n.t('switch.no')) as string,
      }),
    }),
  ],
  id: StepId.CONTACT_FORM,
  submit: async ({ steps, assistantContext }) => {
    try {
      await sendMail(steps as StepDefinition[], assistantContext, {
        contactData: getMarkdownData(steps, assistantContext),
      });
    } catch (e) {
      throw new Error('An error occurred while sending the mail.');
    }
  },
  type: StepTypes.DEFAULT,
};

export default config;
