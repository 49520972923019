



























import { defineComponent, ref } from '@vue/composition-api';
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCardTitle,
  VDialog,
} from 'vuetify/lib';
import { faQuestion } from '@fortawesome/pro-solid-svg-icons';
import GcAlert from '@/components/primitives/GcAlert.vue';
import i18n from '@/plugins/i18n';

export default defineComponent({
  components: {
    GcAlert,
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCardTitle,
    VDialog,
  },
  props: {
    closeBtnText: {
      default: i18n.t('close'),
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
  },
  setup: () => {
    const isOpen = ref(false);
    return {
      faQuestion,
      isOpen,
    };
  },
});
