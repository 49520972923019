















































































































































import { defineComponent, ref } from '@vue/composition-api';
import Subtitle from './Subtitle.vue';
import GcCol from '@/components/primitives/GcCol.vue';
import GcForm from '@/components/primitives/GcForm.vue';
import GcRow from '@/components/primitives/GcRow.vue';
import GcAlert from '@/components/primitives/GcAlert.vue';
import AssistantStepNavigation from '@/components/AssistantStepNavigation.vue';
import { getStepContext } from '@/lib/context';
import { stepComponentProps } from '@/lib/steps/helper';
import FormField from '@/components/FormField.vue';
import { sendEvent } from '@/lib/analytics/dataLayer';

export default defineComponent({
  components: {
    AssistantStepNavigation,
    FormField,
    GcCol,
    GcForm,
    GcRow,
    Subtitle,
    GcAlert,
  },
  props: {
    ...stepComponentProps,
  },
  setup: (props) => {
    const formData = getStepContext(props.assistantContext, props.step);
    const formValid = ref(false);
    const sentSuccessfully = ref(false);

    const onSend = () => {
      if (
        process.env.VUE_APP_GA_EVENT_NAME &&
        process.env.VUE_APP_GA_EVENT_ACTION &&
        process.env.VUE_APP_GA_EVENT_CATEGORY &&
        process.env.VUE_APP_GA_EVENT_LABEL
      ) {
        sendEvent({
          eventAction: process.env.VUE_APP_GA_EVENT_ACTION,
          eventCategory: process.env.VUE_APP_GA_EVENT_CATEGORY,
          eventLabel: process.env.VUE_APP_GA_EVENT_LABEL,
          eventName: process.env.VUE_APP_GA_EVENT_NAME,
        });
      }
      sentSuccessfully.value = true;
    };

    return {
      formData,
      formValid,
      onSend,
      sentSuccessfully,
    };
  },
});
