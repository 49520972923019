/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  addGroupItem: '{groupName} hinzufügen',
  button: {
    navigation: {
      back: 'Zurück',
      next: 'Weiter',
      skip: 'Frage überspringen',
    },
    request: {
      submit: 'Anfrage absenden',
    },
    upload: {
      directory: 'Ordner hochladen',
      files: 'Dateien hochladen',
    },
  },
  cancel: 'Abbrechen',
  close: 'Schließen',
  contact: {
    city: 'Stadt',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    zipcode: 'Postleitzahl',
  },
  continue: 'Fortsetzen',
  customOption: 'Sonstiges',
  edit: 'Bearbeiten',
  foundStateToRestore: {
    button: 'Fortsetzen',
    message:
      'Es wurde ein gespeicherter Fortschritt für diesen Assistenten gefunden. Möchten Sie diesen fortsetzen?',
  },
  goToContactForm: 'Zum Kontaktformular',
  message: {
    error: {
      body: 'Es ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut.',
    },
    success: {
      body: 'Wir werden uns sobald wie möglich bei Ihnen melden.',
      title: 'Vielen Dank für Ihre Anfrage!',
    },
  },
  remove: 'Entfernen',
  requiredField: 'Pflichtfeld',
  skipMessage:
    'Sie fühlen sich unsicher? Kein Problem! Einer unserer Experten erarbeitet den Fragebogen mit Ihnen zusammen.',
  submit: 'Absenden',
  switch: {
    no: 'Nein',
    yes: 'Ja',
  },
  timeSuffix: 'Uhr',
  upload: {
    addMoreFiles: 'Weitere Dateien auswählen',
    dropFiles: 'Und jetzt einfach loslassen!',
    fileSizeExceededWarning:
      'Folgende Datei überschreitet die erlaubte Maximalgröße von {size} MB:|Folgende Dateien überschreiten die erlaubte Maximalgröße von {size} MB:',
    mimeTypeWarning:
      'Folgende Datei hat einen nicht erlaubten Dateityp:|Folgende Dateien haben einen nicht erlaubten Dateityp:',
    placeholder:
      'Klicken Sie hier, um eine Datei auszuwählen oder ziehen Sie diese einfach hier hinein.|Klicken Sie hier, um Dateien auszuwählen oder ziehen Sie diese einfach hier hinein.',
    mobilePlaceholder:
      'Klicken Sie hier, um eine Datei auszuwählen oder ein Foto mit der Kamera aufzunehmen.|Klicken Sie hier, um Dateien auszuwählen oder Fotos mit der Kamera aufzunehmen.',
  },
  validators: {
    invalidEmail: 'Ungültige E-Mail',
    invalidPostalCode: 'Ungültige Postleitzahl',
    invalidPhoneNumber: 'Ungültige Telefonnummer',
    checkNumeric: 'Nur nummerische Werte sind erlaubt',
    checkRequired: 'Dies ist ein Pflichtfeld',
  },
  start: {
    buttonText: 'Assistenten starten',
    step: {
      label: 'Start',
    },
    switchToMobile: 'Zum Gerätewechsel QR-Code scannen: ',
  },
  contactForm: {
    title: 'Kontaktformular',
    step: {
      label: 'Kontakt',
    },
    formFields: {
      companyName: {
        label: 'Firmenname',
      },
      vatId: {
        label: 'USt-IdNr.',
      },
      firstname: {
        label: 'Vorname',
      },
      lastname: {
        label: 'Nachname',
      },
      street: {
        label: 'Straße',
      },
      streetNumber: {
        label: 'Hausnr.',
      },
      zipcode: {
        label: 'PLZ',
      },
      city: {
        label: 'Stadt',
      },
      phone: {
        label: 'Telefonnummer',
      },
      email: {
        label: 'E-Mail-Adresse',
      },
      privacyConfirmation: {
        label: 'Datenschutzerklärung',
        headline:
          'Hiermit stimme ich der <a href="#" target="_blank">Datenschutzerklärung</a> zu.',
        summaryHeadline: 'Ich stimme der Datenschutzerklärung zu',
      },
      newsletterConfirmation: {
        label: 'Angebote und individuelle Vorteile',
        headline:
          'Ich möchte gerne nichts verpassen und über wichtige Neuigkeiten über Angebote und individuelle Vorteile informiert werden.',
        summaryHeadline: 'Ich möchte über Neuigkeiten informiert werden',
      },
    },
  },
  summary: {
    title: 'Zusammenfassung',
    step: {
      label: 'Zusammenfassung',
    },
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
  dealBreaker: {
    title: 'Abbruch',
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
