// Each step has its own identifier
export enum StepId {
  CONTACT_FORM = 'contactForm',
}

// You can optionally divide steps into groups,
// each one having its own identifier
export enum StepCategoryId {}

export enum StepGroupId {}
