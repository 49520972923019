/* eslint-disable sort-keys-fix/sort-keys-fix */
export default {
  start: {
    buttonText: 'Assistenten starten',
    description: '',
    title: 'g|c Assistent',
    step: {
      label: 'Start',
    },
  },
  contactForm: {
    formFields: {
      customerNumber: {
        label: 'STAWAG-Kundennummer',
      },
      subjects: {
        label: 'Themen von Interesse',
        options: {
          carPower: {
            label: 'Autostrom',
          },
          testDriveEvents: {
            label: 'Events',
          },
          promotions: {
            label: 'Förderungen',
          },
          upcomingEvents: {
            label: 'Aktuelle Angebote',
          },
        },
      },
      salutation: {
        label: 'Anrede',
        options: {
          mr: {
            label: 'Herr',
          },
          mrs: {
            label: 'Frau',
          },
          divers: {
            label: 'Divers',
          },
        },
      },
      firstName: {
        label: 'Vorname',
      },
      lastName: {
        label: 'Nachname',
      },
      streetAndNumber: {
        label: 'Straße/Hausnummer',
      },
      note: {
        label: 'Anmerkungen',
      },
      marketing: {
        label: 'Marketing-Einwilligung',
        component: {
          label:
            'Ich willige ein, dass die STAWAG mich mittels Telefon und E-Mail über eigene aktuelle Produkte und Neuigkeiten informieren darf.',
        },
      },
    },
    subtitles: {
      customerNumber: 'STAWAG-Kundennummer',
      subjects: 'An welchen Themen sind Sie interessiert?',
      aboutPerson: 'Persönliche Informationen',
      address: 'Adresse',
      contactData: 'Kontaktdaten',
    },
    sentSuccessfully:
      'Vielen Dank für Ihr Interesse an unseren Veranstaltungen und Angeboten. Wir freuen uns Sie informieren zu dürfen.',
  },
  done: {
    title: 'Fertig',
    step: {
      label: 'Fertig',
    },
  },
};
/* eslint-enable sort-keys-fix/sort-keys-fix */
